import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Row, Col} from "react-bootstrap";
import $ from "jquery";
import {Link} from "react-router-dom";
import {GetData} from "../api_key/GetData";

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsorData: [],
            sponsorDataItems: [],
        }
        document.title = this.props.pageTitle
        this.loadAllSponsors = this.loadAllSponsors.bind(this)
    }

    componentDidMount() {
        this.loadAllSponsors()
    }

    loadAllSponsors() {
        GetData("/front/get_all_sponsors")
            .then(result => {
                this.setState({sponsorData: result.response});
                const listItems = result.response.map((data, index) =>
                    <Col as={"li"} sm={4} key={index}>
                        <div className="inn-sec">
                            {/*<span className="tag">modern arts</span>*/}
                            {/*<img className="img-responsive w-100" src="/assets/images/profile-picture.png" alt=""/>*/}
                            <div className="detail">
                                <Link to={""}>{data.full_name}</Link>
                                <p><span>E-Mail</span>: {data.email}</p>
                                <p><span>Phone</span>: {data.phone}</p>
                            </div>
                        </div>
                    </Col>
                );
                this.setState({sponsorDataItems: listItems, dataLoader: false});
            })
    }

    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <section className="sec-100px gallery">
                        <div className="container">
                            <div className="tittle">
                                <h2>Contact details for help</h2>
                                <hr/>
                            </div>
                            <Row as={"ul"}>
                                {this.state.sponsorDataItems}
                            </Row>
                            <div id="large_image_content" className="large-image-wrapper d-none">
                                <span className="large-image-close" onClick={() => {
                                    $(".large-image-wrapper").addClass("d-none")
                                }}><em className="fa fa-close"></em></span>
                                <img src="" alt="" id={"large_image_content_img"}/>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer/>
            </>
        )
    }
}

export default Gallery;