import React, {useEffect, useState} from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Header from "../Common/Header";
import SubBanner from "../Common/SubBanner";
import Footer from "../Common/Footer";
import {GetData} from "../api_key/GetData";

const breakpoints = [500, 500, 500, 500, 500];
const fetchImages = async () => {
    try {
        const result = await GetData("/front/get_all_image_gallery");
        return result.response.map(img => ({
            src: img.file, // Assuming "file" contains image URLs
            width: 300,
            height: 200
        }));
    } catch (error) {
        console.error("Error fetching images:", error);
        return [];
    }
};

const Gallery = (props) => {
    document.title = props.pageTitle
    const [index, setIndex] = useState(-1);
    const [photos, setPhotos] = useState([]);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        fetchImages().then(result => {
            const photosArray = result.map((photo) => {
                const width = breakpoints[0];
                const height = (photo.height / photo.width) * width;

                return {
                    src: photo.src,
                    width,
                    height,
                    images: breakpoints.map((breakpoint) => {
                        const height = Math.round((photo.height / photo.width) * breakpoint);
                        return {
                            src: photo.src,
                            width: breakpoint,
                            height,
                        };
                    }),
                };
            });
            setPhotos(photosArray)

            const slidesArray = photosArray.map(({ src, width, height, images }) => ({
                src,
                width,
                height,
                srcSet: images.map((image) => ({
                    src: image.src,
                    width: image.width,
                    height: image.height,
                })),
            }));
            setSlides(slidesArray)
        });
    }, []);

    return (
        <>
            <Header/>
            <SubBanner bannerTitle={props.bannerTitle} bannerContent={props.bannerContent}/>

            <div className="content">
                <section className="sec-100px gallery bg-white">
                    <div className="container">
                        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

                        <Lightbox
                            slides={slides}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />
                    </div>
                </section>
            </div>

            <Footer/>
        </>
    );
};

export default Gallery;