export function PostData(type, formData) {
    const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
    let sessionData = null;
    if (cookieDataReplace) {
        sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
    }
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionData ? sessionData[0]?.token || "" : ""}`
            },
            body: JSON.stringify(formData)
        };
        fetch("/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function uploadFiles(type, formData) {
    const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
    let sessionData = null;
    if (cookieDataReplace) {
        sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
    }
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${sessionData ? sessionData[0]?.token || "" : ""}`
            },
        };
        fetch("/api/" + type, requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                resolve(responseJSON)
            })
            .catch((error) => {
                reject(error)
            })
    })
}