import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Contact from "./Pages/Contact";
import Events from "./Pages/Events";
import Gallery from "./Pages/Gallery";
import About from "./Pages/About";
import Sponsors from "./Pages/Sponsors";
import RegisterYourFamily from "./Pages/RegisterYourFamily";
import AdminPages from "./Admin/AdminPages";
import AdminHome from "./Admin/AdminHome";
import AdminGallery from "./Admin/AdminGallery";
import AdminBanners from "./Admin/AdminBanners";
import AdminLogin from "./Admin/AdminLogin";
import AdminEvents from "./Admin/AdminEvents";
import AdminFamily from "./Admin/AdminFamily";
import AdminSponsors from "./Admin/AdminSponsors";
import AdminContactUs from "./Admin/AdminContactUs";
import RegisterYourDaughter from "./Pages/RegisterYourDaughter";
import RegisterYourInterest from "./Pages/RegisterYourInterest";
import AdminEventRegistrations from "./Admin/AdminEventRegistrations";
import AdminDaughters from "./Admin/AdminDaughters";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={
                            <Home
                                pageTitle={"Home | Mokhasan"}
                            />
                        }/>
                        <Route path="/contact" element={
                            <Contact
                                pageTitle={"Contact Us | Mokhasan"}
                                bannerTitle={"Contact Us"}
                                bannerContent={"Keep in touch with our committee members"}
                            />
                        }/>
                        <Route path="/events" element={
                            <Events
                                pageTitle={"Events | Mokhasan"}
                                bannerTitle={"Events"}
                                bannerContent={"Our upcoming events and their details"}
                            />
                        }/>
                        <Route path="/gallery" element={
                            <Gallery
                                pageTitle={"Gallery | Mokhasan"}
                                bannerTitle={"Gallery"}
                                bannerContent={"Take a look at our village places."}
                            />
                        }/>
                        <Route path="/about" element={
                            <About
                                pageTitle={"About | Mokhasan"}
                                bannerTitle={"About"}
                                bannerContent={"Learn more about our Mokhasan Village"}
                            />
                        }/>
                        <Route path="/sponsors" element={
                            <Sponsors
                                pageTitle={"Help | Mokhasan"}
                                bannerTitle={"Help"}
                                bannerContent={"Contact details for any help"}
                            />
                        }/>
                        <Route path="/register_your_family" element={
                            <RegisterYourFamily
                                pageTitle={"Register Your Family | Mokhasan"}
                                bannerTitle={"Register Your Family"}
                                bannerContent={"Fill below form to Register Your Family Members (This is not event registration)"}
                            />
                        }/>
                        <Route path="/register_your_daughter" element={
                            <RegisterYourDaughter
                                pageTitle={"Register Your Daughte (This is not event registration)r | Mokhasan"}
                                bannerTitle={"Register Your Daughter"}
                                bannerContent={"Fill below form to Register Your Daughter"}
                            />
                        }/>
                        <Route path="/register_your_interest" element={
                            <RegisterYourInterest
                                pageTitle={"Register Your Interest | Mokhasan"}
                                bannerTitle={"Register Your Interest"}
                                bannerContent={"Fill below form to Register Your Interest"}
                            />
                        }/>
                        <Route path="/adminPages" element={<AdminPages pageTitle={"Admin Home | Mokhasan"}/>}>
                            <Route path="" element={
                                <AdminLogin pageTitle={"Admin Login | Mokhasan"}/>
                            }/>
                            <Route path="home" element={
                                <AdminHome pageTitle={"Admin Home | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="gallery" element={
                                <AdminGallery pageTitle={"Admin Gallery | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="banners" element={
                                <AdminBanners pageTitle={"Admin Banners | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="events" element={
                                <AdminEvents pageTitle={"Admin Events | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="event_registrations" element={
                                <AdminEventRegistrations pageTitle={"Admin Event Registrations | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="family" element={
                                <AdminFamily pageTitle={"Admin Family | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="daughters" element={
                                <AdminDaughters pageTitle={"Admin Family | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="sponsors" element={
                                <AdminSponsors pageTitle={"Admin Helpers | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                            <Route path="contact_us" element={
                                <AdminContactUs pageTitle={"Admin Contact Us | Mokhasan"} isAdmin={this.state.isAdmin}/>
                            }/>
                        </Route>
                        <Route path="*" element={
                            <NotFound
                                pageTitle={"404 Not Found | Mokhasan"}
                                bannerTitle={"404"}
                                bannerContent={"The Page Not Found"}
                            />
                        }/>
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
