import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {GetData} from "../api_key/GetData";
import {Alert, Button, Modal} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import DataTable from "react-data-table-component";

class AdminFamily extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            familyColumns: [],
            familyData: [],
        }
        document.title = this.props.pageTitle
        this.loadAllFamilyMembers = this.loadAllFamilyMembers.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteFamilyMember = this.handleDeleteFamilyMember.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
    }

    componentDidMount() {
        this.loadAllFamilyMembers()
    }

    handleDeleteFamilyMember() {
        PostData("/familyMembers/delete_family_member", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    this.setState({
                        commonAlert: {
                            show: false,
                            variant: "success",
                            message: result.response,
                        }
                    })
                }
            })
        this.loadAllFamilyMembers()
        this.handleClose()
    }

    loadAllFamilyMembers() {
        GetData("/familyMembers/get_all")
            .then(result => {
                this.setState({
                    familyData: result.response,
                    familyColumns: [
                        {
                            name: <p className="h6 font-weight-bold">First Name</p>,
                            selector: (row) => row.first_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Last Name</p>,
                            selector: (row) => row.last_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Father Name</p>,
                            selector: (row) => row.father_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Grand Father</p>,
                            selector: (row) => row.grandfather_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Email</p>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Phone</p>,
                            selector: (row) => row.phone,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Address</p>,
                            selector: (row) => row.full_address,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Vas Name</p>,
                            selector: (row) => row.vas_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Delete</p>,
                            cell: (data) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    uuid: data.uuid,
                                })
                            }}>
                                <em className="fa fa-trash"></em>
                            </Button>
                        },
                    ],
                })
            })
    }

    handleClose() {
        this.setState({
            handleDeletePopup: false,
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
        })
    }
    downloadReport() {
        GetData("/familyMembers/downloadFamilyRegistrations", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    let a = document.createElement("a");
                    a.setAttribute("href", result.response);
                    document.body.appendChild(a);
                    const date = new Date();
                    const dateString = date.toISOString().replace(/[:.]/g, "-"); // Convert to ISO string and replace : and . with -
                    const fileName = `Family-Registration-${dateString}.xlsx`; // Add the date string to the file name
                    a.setAttribute("download", fileName);
                    a.click();
                    a.remove();

                }
            })
    }
    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.downloadReport()
                            }}>Export Excel</Button>
                        </div>
                        <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant}
                               className="rounded-lg">
                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                        </Alert>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose} centered backdrop="static"
                               keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>Are you sure want to delete this Family entry?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={this.handleDeleteFamilyMember}>Yes</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>No</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="row g-4">
                            <div className="col-sm-12">
                                <div className="shadow rounded p-4 table-responsive">
                                    <DataTable
                                        columns={this.state.familyColumns}
                                        data={this.state.familyData}
                                        pagination
                                        className={"table-hover"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminFamily;