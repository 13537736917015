import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import {GetData} from "../api_key/GetData";
import DataTable from "react-data-table-component";

class AdminSponsors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUsername: "",
            loginUserUUID: "",
            loginUserToken: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            addNewSponsorModal: false,
            sponsorImage: "",
            sponsorName: "",
            sponsorNameErr: "",
            sponsorEmail: "",
            sponsorEmailErr: "",
            sponsorPhone: "",
            sponsorPhoneErr: "",
            sponsorColumns: [],
            sponsorData: [],
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.inertNewSponsor = this.inertNewSponsor.bind(this)
        this.uploadSponsorImage = this.uploadSponsorImage.bind(this)
        this.loadAllSponsors = this.loadAllSponsors.bind(this)
        this.handleDeleteSponsor = this.handleDeleteSponsor.bind(this)
    }

    componentDidMount() {
        const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));

        this.setState({
            loginUsername: sessionData[0].full_name,
            loginUserUUID: sessionData[0].uuid,
            loginUserToken: sessionData[0].token
        });
        this.loadAllSponsors()
    }

    handleDeleteSponsor() {
        PostData("/sponsors/delete_sponsor", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    this.setState({
                        commonAlert: {
                            show: false,
                            variant: "success",
                            message: "Sponsor deleted successfully"
                        }
                    })
                }
            })
        this.loadAllSponsors()
        this.handleClose()
    }

    inertNewSponsor() {
        let formData = {
            uuid: this.state.uuid,
            full_name: this.state.sponsorName,
            email: this.state.sponsorEmail,
            phone: this.state.sponsorPhone,
        };
        PostData(this.state.addNewSponsor ? "/sponsors/add_sponsor" : "/sponsors/edit_sponsor", formData)
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        sponsorNameErr: "",
                        sponsorEmailErr: "",
                        sponsorPhoneErr: "",
                        sponsorName: "",
                        sponsorEmail: "",
                        sponsorPhone: "",
                        addNewSponsorModal: false,
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: result.response
                        }
                    })
                    this.loadAllSponsors()
                } else if (result.status === false) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.message
                        }
                    })

                    if (result.message.full_name) {
                        this.setState({sponsorNameErr: result.message.full_name})
                    } else {
                        this.setState({sponsorNameErr: ""})
                    }

                    if (result.message.phone) {
                        this.setState({sponsorPhoneErr: result.message.phone})
                    } else {
                        this.setState({sponsorPhoneErr: ""})
                    }
                } else if (result.status === 401) {
                    this.setState({
                        sponsorNameErr: "",
                        sponsorEmailErr: "",
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message
                        }
                    })
                }
            })
    }

    loadAllSponsors() {
        GetData("/sponsors/get_all")
            .then(result => {
                this.setState({
                    sponsorData: result.response,
                    sponsorColumns: [
                        {
                            name: <p className="h6 font-weight-bold">Full Name</p>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">E-Mail</p>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Phone</p>,
                            selector: (row) => row.phone,
                            sortable: true
                        },
                        {
                            name: <p className="h6 font-weight-bold">Edit</p>,
                            cell: (data) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    addNewSponsor: false,
                                    addNewSponsorModal: true,
                                    sponsorName: data.full_name,
                                    sponsorEmail: data.email,
                                    sponsorPhone: data.phone,
                                    uuid: data.uuid,
                                })
                            }}>
                                <em className="fa fa-edit"></em>
                            </Button>
                        },
                        {
                            name: <p className="h6 font-weight-bold">Delete</p>,
                            cell: (data) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    uuid: data.uuid,
                                })
                            }}>
                                <em className="fa fa-trash"></em>
                            </Button>
                        },
                    ],
                })
            })
    }

    handleClose() {
        this.setState({
            addNewSponsorModal: false,
            handleDeletePopup: false,
            sponsorImage: "",
            sponsorName: "",
            sponsorEmail: "",
            sponsorPhone: "",
            sponsorPhoneErr: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            sponsorNameErr: "",
            sponsorEmailErr: "",
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    uploadSponsorImage(e) {
        this.setState({
            sponsorImage: e.target.files[0]
        })
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.setState({
                                    addNewSponsorModal: true,
                                    addNewSponsor: true,
                                    commonAlert: {
                                        show: false,
                                        variant: "",
                                        message: ""
                                    },
                                })
                            }}>Add New Sponsor</Button>
                        </div>
                        <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant}
                               className="rounded-lg">
                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                        </Alert>
                        <Modal show={this.state.addNewSponsorModal} onHide={this.handleClose} backdrop={"static"}
                               centered keyboard={false}>
                            <Modal.Header closeButton className={"border-light"}>
                                <Modal.Title className={"text-dark"}>Add New Sponsor</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant}
                                           className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                    </Alert>
                                    {/*<Form.Group className="mb-3" controlId="sponsorImage">
                                        <Form.Label>Upload Sponsor Image</Form.Label>
                                        <Form.Control type="file"
                                                      className={"bg-white border-light"}
                                                      name={"sponsorImage"}
                                                      onChange={this.uploadSponsorImage}/>
                                    </Form.Group>*/}
                                    <Form.Group className="mb-3" controlId="sponsorName">
                                        <Form.Label>Sponsor Name</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder="Sponsor Name"
                                                      className={"bg-white border-light"}
                                                      name={"sponsorName"}
                                                      value={this.state.sponsorName}
                                                      onChange={this.changeValue}/>
                                        {this.state.sponsorNameErr && <Form.Label
                                            className={"text-danger"}>{this.state.sponsorNameErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="sponsorEmail">
                                        <Form.Label>Sponsor E-Mail</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder="Sponsor E-Mail"
                                                      className={"bg-white border-light"}
                                                      name={"sponsorEmail"}
                                                      value={this.state.sponsorEmail}
                                                      onChange={this.changeValue}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="sponsorPhone">
                                        <Form.Label>Sponsor Phone</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder="Sponsor Phone"
                                                      className={"bg-white border-light"}
                                                      name={"sponsorPhone"}
                                                      value={this.state.sponsorPhone}
                                                      onChange={this.changeValue}/>
                                        {this.state.sponsorPhoneErr && <Form.Label
                                            className={"text-danger"}>{this.state.sponsorPhoneErr}</Form.Label>}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className={"border-light"}>
                                <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Close</Button>
                                <Button variant={"success"}
                                        onClick={() => this.inertNewSponsor()}
                                        className={"ml-2"}>Save Changes</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose} centered backdrop="static"
                               keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>Are you sure want to delete Sponsor?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={this.handleDeleteSponsor}>Yes</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>No</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="row g-4">
                            <div className="col-sm-12">
                                <div className="shadow rounded p-4 table-responsive">
                                    <DataTable
                                        columns={this.state.sponsorColumns}
                                        data={this.state.sponsorData}
                                        pagination
                                        className={"table-hover"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminSponsors;