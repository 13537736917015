import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import Dropzone from 'react-dropzone'
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {PostData, uploadFiles} from "../api_key/PostData";
import {GetData} from "../api_key/GetData";

class AdminGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commonAlert: {show: false, variant: "", message: ""},
            galleryAlert: {show: false, variant: "", message: ""},
            accepted: [],
            rejected: [],
            uploadImagesModal: false,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.saveAllImages = this.saveAllImages.bind(this)
        this.loadAllImages = this.loadAllImages.bind(this)
    }

    componentDidMount() {
        const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
        this.setState({
            loginUsername: sessionData[0].full_name,
            loginUserUUID: sessionData[0].uuid,
            loginUserToken: sessionData[0].token
        });
        this.loadAllImages()
    }

    loadAllImages () {
        GetData("/imageGallery/get_all")
            .then(result => {
                let galleryArray = []
                if(result.status === true) {
                    if (result.response.length > 0) {
                        galleryArray = result.response.map(data => <div className="col-sm-12 col-md-6 col-lg-3" key={data.uuid}>
                            <div className="shadow rounded p-3 position-relative admin-gallery">
                                <img className="img-responsive w-100 rounded" src={data.file} alt=""
                                     style={{height: 160, objectFit: "cover"}}/>
                                <Button className="admin-gallery-btn btn btn-danger" onClick={(event) => {
                                    PostData("/imageGallery/delete_image_gallery", {uuid: data.uuid})
                                        .then(result => {
                                            this.setState({
                                                commonAlert: {show: true, variant: "success", message: result.response}
                                            })
                                            setTimeout(() => {
                                                this.setState({commonAlert: {show: false, variant: "success"}})
                                            }, 3000);
                                        })
                                    this.loadAllImages()
                                }}>
                                    <em className="fa fa-trash"></em>
                                </Button>
                            </div>
                        </div>)
                    } else {
                        galleryArray = <h5 className="text-center">No Images Found</h5>
                    }
                }
                this.setState({galleryArray})
            })
    }

    onDrop = (accepted, rejected) => {
        this.setState({
            accepted: accepted,
            rejected: rejected,
            uploadFileContent: accepted[0],
        });
    }

    saveAllImages() {
        if(this.state.uploadFileContent === undefined) {
            this.setState({
                galleryAlert: {show: true, variant: "danger", message: "Please Select Image."},
            })
            return;
        }
        const formData = new FormData();
        formData.append('file', this.state.uploadFileContent);
        uploadFiles("/imageGallery/add_gallery", formData)
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        galleryAlert: {show: true, variant: "success", message: result.response},
                        uploadFileContent: undefined,
                    })
                    setTimeout(() => {
                        this.setState({galleryAlert: {show: false, variant: "success"}})
                    }, 2500);
                    this.loadAllImages()
                } else if (result.status === 401) {
                    this.setState({
                        galleryAlert: {show: true, variant: "danger", message: result.messages.message}
                    })
                } else if (result.status === false) {
                    this.setState({
                        galleryAlert: {show: true, variant: "danger", message: result.message.file}
                    })
                }
            })
    }

    handleClose() {
        this.setState({
            uploadImagesModal: false,
            commonAlert: {show: false, variant: "", message: ""},
            galleryAlert: {show: false, variant: "", message: ""},
            uploadFileContent: undefined,
        })
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.setState({uploadImagesModal: true})
                            }}>Add New Images</Button>
                        </div>
                        <Modal show={this.state.uploadImagesModal} onHide={this.handleClose} backdrop={"static"} centered keyboard={false}>
                            <Modal.Header closeButton className={"border-light"}>
                                <Modal.Title className={"text-dark"}>Add New Images</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.galleryAlert.show} variant={this.state.galleryAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.galleryAlert.message}</h6>
                                </Alert>
                                <Form className={"upload-images-form"}>
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        multiple={true}
                                    >
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} name="fileUpload" id="fileUpload"/>
                                                {isDragActive ? "Drop here!" : 'Click me or drag a file to upload!'}
                                            </div>
                                        )}
                                    </Dropzone>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className={"border-light"}>
                                <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Close</Button>
                                <Button variant={"success"} onClick={this.saveAllImages} className={"ml-2"}>Save Changes</Button>
                            </Modal.Footer>
                        </Modal>

                        <div className="row g-4">
                            <div className="col-12">
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                </Alert>
                            </div>
                            {this.state.galleryArray}
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminGallery;