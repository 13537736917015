import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import MokhasanMap from "../Common/MokhasanMap";
import HoursOfVisiting from "../Common/HoursOfVisiting";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            contact_website: "",
            contact_description: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            }
        }
        document.title = this.props.pageTitle
        this.submitContactForm = this.submitContactForm.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.setTokenValue = this.setTokenValue.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitContactForm() {
        let formData = {
            name: this.state.contact_name,
            email: this.state.contact_email,
            phone: this.state.contact_phone,
            website: this.state.contact_website,
            description: this.state.contact_description,
            googleRecaptchaResponse: this.state.reCaptchaToken
        }
        PostData("/front/contact_us", formData)
            .then(result => {
                this.setState({
                    contact_name: "",
                    contact_email: "",
                    contact_phone: "",
                    contact_website: "",
                    sponsorEmail: "",
                    contact_description: "",
                    commonAlert: {
                        show: true,
                        variant: "success",
                        message: result.response
                    }
                })
            })
    }
    setTokenValue(token) {
        this.setState({reCaptchaToken: token})
    }
    handleClose() {
        this.setState({
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            contact_website: "",
            contact_description: "",
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us">
                    <div className="container">
                        <Row>
                            <Col md={7}>
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                </Alert>
                                <div className="contact-form">
                                    <Form id="contact_form" className="contact-form" method="post"
                                          onSubmit="return false">
                                        <Row as={"ul"}>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_name"
                                                                  id="contact_name"
                                                                  placeholder="Name"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_name}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_email"
                                                                  id="contact_email"
                                                                  placeholder="Email"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_email}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="number"
                                                                  name="contact_phone"
                                                                  id="contact_phone"
                                                                  placeholder="Phone"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_phone}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={6}>
                                                <label>
                                                    <Form.Control type="text"
                                                                  name="contact_website"
                                                                  id="contact_website"
                                                                  placeholder="Website"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_website}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={12}>
                                                <label>
                                                    <Form.Control as="textarea"
                                                                  name="contact_description"
                                                                  id="contact_description"
                                                                  rows="5"
                                                                  placeholder="Your Need & Description"
                                                                  onChange={this.changeValue}
                                                                  value={this.state.contact_description}/>
                                                </label>
                                            </Col>
                                            <Col as={"li"} sm={12}>
                                                <Button onClick={this.submitContactForm}>Submit</Button>
                                                <Button onClick={this.handleClose} className={"ml-2"}>Reset</Button>
                                            </Col>
                                        </Row>
                                        <GoogleReCaptchaProvider
                                            reCaptchaKey="6Lcni2wkAAAAAKykRhLubTzdf1b_ew-uKwQO3Wnc"
                                            scriptProps={{
                                                async: false, // optional, default to false,
                                                defer: false, // optional, default to false
                                                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                                nonce: undefined // optional, default undefined
                                            }}
                                        >
                                            <GoogleReCaptcha onVerify={this.setTokenValue}/>
                                        </GoogleReCaptchaProvider>
                                    </Form>
                                </div>
                            </Col>

                            <Col md={5} className={"about"}>
                                <HoursOfVisiting/>
                            </Col>
                        </Row>
                    </div>
                </section>

                <div style={{height: "520px"}}>
                    <MokhasanMap/>
                </div>
            </div>

            <Footer/>
        </>)
    }
}

export default NotFound;