import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import MokhasanMap from "../Common/MokhasanMap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HoursOfVisiting from "../Common/HoursOfVisiting";
import "./SliderCss.css"
import $ from "jquery";
import {GetData} from "../api_key/GetData";
import moment from 'moment';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            revSlider: {
                infinite: true,
                slidesToShow: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 4000,
                autoplayHoverPause: true,
                singleItem: true,
                lazyLoad: true,
                animateOut: 'fadeOut',
                loop: true,
            },
            galleryImageList: [],
        }
        document.title = this.props.pageTitle
        this.loadAllEvents = this.loadAllEvents.bind(this)
        this.loadAllImageGallery = this.loadAllImageGallery.bind(this)
    }

    showLargeImage(e) {
        $("#large_image_content_img").attr("src", e.currentTarget.getAttribute("data-src"))
        $("#large_image_content").removeClass("d-none")
    }

    componentDidMount() {
        this.loadAllEvents()
        this.loadAllImageGallery()
    }

    loadAllImageGallery() {
        GetData("/front/get_all_image_gallery")
            .then(result => {
                let galleryImages = result.response.map((data, index) =>
                    ((index < 3) && <Col as={"li"} sm={4} key={index}>
                        <div className="inn-sec">
                            <div className="hover-info">
                                <div className="position-center-center">
                                    <Button data-src={data.file} data-rel="prettyPhoto"
                                            className="prettyPhoto lightzoom zoom" onClick={this.showLargeImage}>
                                        <em className="ion-image"></em>
                                    </Button>
                                </div>
                            </div>
                            <img className="img-responsive" src={data.file} alt=""/>
                        </div>
                    </Col>)
                )
                this.setState({galleryImageList: galleryImages})
            })

        GetData("/banners/get_all")
            .then(result => {
                let bannerArray = []
                if(result.status === true) {
                    if (result.response.length > 0) {
                        bannerArray = result.response.map(data => <div className="slider_slide" key={data.uuid}>
                            <img src={data.image} data-bgposition="center center" alt="" style={{width: "100%"}}/>
                        </div>)
                    } else {
                        bannerArray = <div className="slider_slide">
                            <img src="/assets/images/logo.png" data-bgposition="center center" alt=""
                                 style={{width: "100%"}}/>
                        </div>
                    }
                }
                this.setState({bannerArray})
            })
    }

    loadAllEvents() {
        GetData("/front/get_upcoming_event")
            .then(result => {
                this.setState({eventsData: result.response});
                let listItems = []
                if(result.response.length > 0) {
                    listItems = result.response.map((data, index) =>
                        <Row key={index}>
                            <Col md={12}>
                                <ListGroup as={"ul"}>
                                    <ListGroup.Item as={"li"} className={"col-sm-6 no-padding bg-transparent border-0"}>
                                        <img className="img-responsive" src={data.banner_image_file} alt=""/>
                                        <div className="date">{moment(data.start_date_time).date()}<p>{moment(data.start_date_time).format('MMMM, ')}</p></div>
                                    </ListGroup.Item>
                                    <ListGroup.Item as={"li"} className={"col-sm-6 no-padding bg-transparent border-0"}>
                                        <div className="event-detail">
                                            <h4>{data.title}</h4>
                                            <span><em className="ion-ios-location-outline"></em>{data.location}</span>
                                            <span>
                                                <em className="ion-ios-clock-outline"></em>
                                                {moment(data.start_date_time).format('MMMM Do')} To {moment(data.end_date_time).format('MMMM Do')}
                                            </span>
                                            <p className={"event-description-pre"}>{data.description}</p>
                                            <Link to={"/register_your_interest?uuid=" + data.uuid} className={"btn btn-primary d-inline-block w-100 w-md-50 mr-2"}>
                                                Register Your Interest
                                            </Link>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    );
                } else {
                    listItems = <h5 className="text-center mb-4">No Event Found</h5>
                }
                this.setState({eventsDataItems: listItems, dataLoader: false});
            })
    }

    render() {
        return (
            <>
                <Header/>
                <div className="content">
                    <section className="home-slider">
                        <div className="tp-banner">
                            <Slider {...this.state.revSlider}>
                                {this.state.bannerArray}
                            </Slider>
                        </div>
                    </section>

                    <section className="sec-100px about">
                        <div className="container">
                            <Row>
                                <Col md={7}>
                                    <h4>Welcome to Mokhasan Parivar</h4>
                                    <hr/>
                                    <p>Mokhasan is a Village in Kalol Taluka in Gandhinagar District of Gujarat State, India.</p>
                                    <p>This village have all types of caste available like Patel, Thakore, Bharmin, Raval, Harijan, Vaghri and Rabari.</p>
                                    <p>In old age one Mokha Rabari established this village.</p>
                                    <p>From all houses one or two people are in US or CANADA.</p>
                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        <Link className={"register_button"} to={"/register_your_family"}>
                                            Register your family <em className="fa fa-angle-right"></em>
                                        </Link>
                                        <Link className={"register_button"} to={"/register_your_daughter"}>
                                            Register your Daughter <em className="fa fa-angle-right"></em>
                                        </Link>
                                    </div>
                                </Col>

                                <Col md={5}>
                                    <HoursOfVisiting/>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="history">
                        <Row>
                            <Col md={4} className={"no-padding"}>
                                <div className="website-img"></div>
                            </Col>
                            <Col md={8} className={"no-padding"}>
                                <div className="history-detail">
                                    <Row as={"ul"} className="row">
                                        <Col as={"li"} md={3}>
                                            <h4>Our history</h4>
                                            <hr/>
                                        </Col>
                                        <Col as={"li"} md={9}>
                                            <p>
                                                Mokhasan was founded by one Mokha Rabari in early 90s. It is located 19 KM towards west from District head quarters Gandhinagar. 10 KM from . 18 KM from State capital Gandhinagar.
                                                <br/>
                                                <br/>
                                                There are three big Swaminarayan temples one Krishna temple and one Shivaji temple in Mokhasan.
                                                <br/>
                                                <br/>
                                                Mokhasan's local language is Gujarati. Mokhasan's Total population is approximately 2841 and number of houses are 574. Female Population is 49.1%.
                                                <br/>
                                                <br/>
                                                This Village has great literacy rate with 70.4% Male and the Female Literacy rate is 31.5%.
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row as={"ul"} className="on-view">
                                        <Col as={"li"} md={3}>
                                            <h4>Places Nearby</h4>
                                            <hr/>
                                        </Col>
                                        <Col as={"li"} md={9}>
                                            <Row as="ul">
                                                <Col as={"li"} className={"col-xs-6"}>
                                                    <p>Dingucha ( 3 KM )</p>
                                                    <p>Nardipur ( 3 KM )</p>
                                                    <p>Sardhav ( 4 KM )</p>
                                                </Col>
                                                <Col as={"li"} className={"col-xs-6"}>
                                                    <p>Pansar ( 4 KM )</p>
                                                    <p>Nava ( 5 KM )</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>

                    <section className="sec-100px gallery">
                        <div className="container">
                            <div className="tittle">
                                <h2>GALLERY</h2>
                                <hr/>
                                <p>Take a look at our village places</p>
                            </div>
                            <Row as={"ul"}>
                                {this.state.galleryImageList}
                            </Row>
                            <div className="text-center">
                                <Link to={"/gallery"} className={"d-inline-block px-5 register_button"}>
                                    Show All Images <em className="fa fa-angle-right"></em>
                                </Link>
                            </div>
                        </div>
                    </section>

                    <section className="sec-100px event">
                        <div className="container">
                            <div className="tittle">
                                <h2>EVENTS</h2>
                                <hr/>
                                <p>Stay connected and be part of the action - check out our upcoming events!</p>
                            </div>
                            {this.state.eventsDataItems}
                            <div className="text-center">
                                <Link to={"/events"} className={"d-inline-block px-5 register_button"}>
                                    Show All Events <em className="fa fa-angle-right"></em>
                                </Link>
                            </div>
                        </div>
                    </section>

                    <div style={{height: "520px"}}>
                        <MokhasanMap/>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default Home;