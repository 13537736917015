import React from "react"
import {Link, Navigate} from "react-router-dom";
import {LoginData} from "../api_key/LoginData";
import {Alert, Button, Form} from "react-bootstrap";

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail: "",
            loginEmailErr: "",
            loginPassword: "",
            loginPasswordErr: "",
            loginRedirect: false,
            commonAlert: {
                show: false,
                variant: "",
                message: "",
            }
        }
        document.title = this.props.pageTitle
        this.submitLogin = this.submitLogin.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    onChangeInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitLogin() {
        let loginUserData = {
            email: this.state.loginEmail,
            password: this.state.loginPassword
        }
        LoginData('/admins/admin_login', loginUserData)
            .then((result) => {
                console.log(result)
                if (result.status === true) {
                    const newStudent = [];
                    newStudent.full_name = result.response.full_name;
                    newStudent.uuid = result.response.uuid;
                    newStudent.email = result.response.email;
                    newStudent.token = result.response.token;
                    document.cookie = 'mokhasanLogin=[{' +
                        '"full_name":"' + newStudent.full_name + '",' +
                        '"uuid":"' + newStudent.uuid + '",' +
                        '"email":"' + newStudent.email + '",' +
                        '"token":"' + newStudent.token + '"' +
                        '}]; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                    let cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
                    let cookieData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
                    this.setState({
                        loginRedirect: true,
                        fullName: cookieData[0].full_name,
                        commonAlert: {
                            show: false,
                            variant: "success",
                            message: "Login Successful",
                        }
                    });
                } else if (result.status === 400) {
                    this.setState({
                        loginRedirect: false,
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message
                        },
                    });
                } else if (result.status === false) {
                    if(result.message.length > 0) {
                        this.setState({
                            loginRedirect: false,
                            commonAlert: {
                                show: true,
                                variant: "danger",
                                message: result.message
                            },
                        });
                    } else {
                        this.setState({
                            loginRedirect: false,
                            loginEmailErr: result.message.email,
                            loginPasswordErr: result.message.password,
                        });
                    }
                }
            })
    }

    render() {
        let cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let requiresLogin = true;
        if (cookieDataReplace) {
            let cookieData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
            requiresLogin = !cookieData;
        }

        return (<>
            {(!requiresLogin) && <Navigate to="/adminPages/home" replace={true}/>}
            <div className="container-fluid">
                <div className="row h-100 align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="shadow rounded p-4 p-sm-5 my-4 mx-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <Link to="/adminPanel">
                                    <h3 className="text-primary"><i className="fa fa-user-edit me-2"></i> Mokhasan</h3>
                                </Link>
                                <h3 className={"text-dark"}>Sign In</h3>
                            </div>
                            <Form>
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                </Alert>
                                <Form.Group className="form-floating mb-3" controlId={"loginEmail"}>
                                    <Form.Control type="email"
                                                  onChange={this.onChangeInput}
                                                  className="form-control bg-white"
                                                  value={this.state.loginEmail}
                                                  name={"loginEmail"} placeholder="name@example.com"/>
                                    <Form.Label>Email address</Form.Label>
                                    <span className={"text-danger"}>{this.state.loginEmailErr}</span>
                                </Form.Group>
                                <Form.Group className="form-floating mb-4" controlId={"loginPassword"}>
                                    <Form.Control type="password"
                                                  onChange={this.onChangeInput}
                                                  name={"loginPassword"} placeholder="Password"
                                                  value={this.state.loginPassword}
                                                  className="form-control bg-white"/>
                                    <Form.Label>Password</Form.Label>
                                    <span className={"text-danger"}>{this.state.loginPasswordErr}</span>
                                </Form.Group>
                                {/*<div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                        <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                    </div>
                                    <a href="">Forgot Password</a>
                                </div>*/}
                                <Button variant={"primary"} className="py-3 w-100 mb-4" onClick={this.submitLogin}>Sign In</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default AdminLogin;