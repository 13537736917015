import React from "react";
import {Link, NavLink} from "react-router-dom";

class AdminSidebar extends React.Component {
    render() {
        return (<>
            <div className="sidebar pe-4 pb-3">
                <nav className="navbar bg-secondary navbar-dark">
                    <Link to={""} className="navbar-brand mx-4 mb-3">
                        <h3 className="text-primary">
                            <img src="/assets/images/logo-footer.png" alt="" className="w-100 p-2"/>
                        </h3>
                    </Link>
                    <div className="navbar-nav w-100">
                        <NavLink to={"/adminPages/home"} className="nav-item nav-link"><i className="fa fa-dashboard me-2"></i>Dashboard</NavLink>
                        <NavLink to={"/adminPages/banners"} className="nav-item nav-link"><i className="fa fa-slideshare me-2"></i> Banners</NavLink>
                        <NavLink to={"/adminPages/gallery"} className="nav-item nav-link"><i className="fa fa-image me-2"></i> Gallery</NavLink>
                        <NavLink to={"/adminPages/events"} className="nav-item nav-link"><i className="fa fa-calendar me-2"></i> Events</NavLink>
                        <NavLink to={"/adminPages/event_registrations"} className="nav-item nav-link"><i className="fa fa-ticket me-2"></i>Registrations</NavLink>
                        <NavLink to={"/adminPages/family"} className="nav-item nav-link"><i className="fa fa-users me-2"></i> Family</NavLink>
                        <NavLink to={"/adminPages/daughters"} className="nav-item nav-link"><i className="fa fa-female me-2"></i> Daughters</NavLink>
                        <NavLink to={"/adminPages/sponsors"} className="nav-item nav-link"><i className="fa fa-user me-2"></i> Sponsors</NavLink>
                        <NavLink to={"/adminPages/contact_us"} className="nav-item nav-link"><i className="fa fa-phone me-2"></i> Contact Us</NavLink>
                    </div>
                </nav>
            </div>
        </>);
    }
}

export default AdminSidebar;