import React from "react";
import {Button, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import {GetData} from "../api_key/GetData";
import moment from "moment";

class HoursOfVisiting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
        }
        this.getUpcomingEvent = this.getUpcomingEvent.bind(this)
    }

    componentDidMount() {
        this.getUpcomingEvent()
    }

    getUpcomingEvent() {
        GetData("/front/get_upcoming_event")
            .then(result => {
                let listItems = []
                if(result.response.length > 0) {
                    this.setState({eventsData: result.response});
                    listItems = result.response.map((data, index) =>
                        <ListGroup as={"ul"} className={"mb-0"} key={index}>
                            <ListGroup.Item as={"li"} className={"col-md-5 no-padding bg-transparent border-0"}>
                                <h5>{moment(data.start_date_time).format('MMMM Do YYYY')}</h5>
                                <p>{data.location}</p>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"col-md-7 no-padding bg-transparent border-0"}>
                                <span className="appoiment px-1">{data.title}</span>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"}
                                            className={"col-md-12 no-padding bg-transparent border-0 d-flex flex-wrap flex-md-nowrap"}>
                                <Link to={"/register_your_interest?uuid=" + data.uuid}
                                      className={"btn btn-primary d-inline-block w-100 w-md-50 mr-2"}>Register Your
                                    Interest</Link>
                                <Link to={"/events"} className={"btn  d-inline-block w-100 w-md-50 mr-2"}>More
                                    info</Link>
                            </ListGroup.Item>
                        </ListGroup>
                    );
                } else {
                    listItems = <ListGroup as={"ul"} className={"mb-0"}>
                        <ListGroup.Item as={"li"} className={"no-padding bg-transparent border-0 text-center"}>
                            <h5>No Upcoming Event Found</h5>
                        </ListGroup.Item>
                    </ListGroup>
                }
                this.setState({upComingEvent: listItems, dataLoader: false});
            })
    }

    render() {
        return (
            <div className="hrs">
                <h3><em className="ion-ios-clock-outline"></em> Upcoming Event</h3>
                {this.state.upComingEvent}
            </div>
        )
    }
}

export default HoursOfVisiting;