import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {GetData} from "../api_key/GetData";
import {Link} from "react-router-dom";

class AdminHome extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
        this.state = {
            events:"",
            family_registrations:"",
            daughter_registrations:"",
            sponsors:"",
            event_registrations:"",
            gallery:"",
            contactus:"",
        }
        this.loadDashboard = this.loadDashboard.bind(this)
    }
    componentDidMount() {
        this.loadDashboard()
    }

    loadDashboard() {
        GetData("/dashboard/get_dashboard_counts")
            .then(result => {
                let response = result.response
                this.setState({
                    events:response.events,
                    family_registrations:response.family_registrations,
                    daughter_registrations:response.daughter_registrations,
                    sponsors:response.sponsors,
                    event_registrations:response.event_registrations,
                    gallery:response.gallery,
                    contactus:response.contactus,
                })
            })
    }
    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="row g-4">
                            <div className="col-sm-6 col-xl-3">
                                <Link to="/adminPages/events">
                                    <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-calendar fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Total Events</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.events}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xl-3" >
                                <Link to="/adminPages/event_registrations">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-ticket fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Event Registrations</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.event_registrations}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <Link to="/adminPages/family">
                                    <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-users fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Family Registrations</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.family_registrations}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <Link to="/adminPages/daughters">
                                    <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-female fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Daughter Registrations</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.daughter_registrations}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <Link to="/adminPages/gallery">
                                    <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-image fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Total Images</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.gallery}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <Link to="/adminPages/contact_us">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-phone fa-3x text-primary"></i>
                                    <div className="ms-3">
                                        <p className="mb-2">Contact Requests</p>
                                        <h1 className="text-dark mb-0 float-end">{this.state.contactus}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminHome;