import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import Select from "react-select";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import './RegisterYourFamily.css';

class RegisterYourFamily extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addFamilyMemberModal: false,
            familyMemberList: [],
            first_name: "",
            last_name: "",
            father_name: "",
            grandfather_name: "",
            phone: "",
            email: "",
            vas_name: "",
            full_address: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            reCaptchaToken: "",
            married_options: [
                {value: 1, label: "Married"},
                {value: 2, label: "Unmarried"},
                {value: 3, label: "Divorced"},
                {value: 4, label: "Widowed"},
            ]
            ,
            gender_options: [
                {value: 1, label: "Male"},
                {value: 2, label: "Female"},
                {value: 3, label: "Other"},
                {value: 4, label: "Prefer not to say"},
            ],
            relationship_options: [
                {value: 1, label: "Mother"},
                {value: 2, label: "Father"},
                {value: 3, label: "Grand Father"},
                {value: 4, label: "Grand Mother"},
                {value: 5, label: "Spouse"},
                {value: 6, label: "Brother"},
                {value: 7, label: "Sister"},
                {value: 8, label: "Son"},
                {value: 9, label: "Daughter"},
                {value: 10, label: "Other"},
            ],
        }
        document.title = this.props.pageTitle
        this.submitFamilyForm = this.submitFamilyForm.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.setTokenValue = this.setTokenValue.bind(this)
    }

    setTokenValue(token) {
        this.setState({reCaptchaToken: token})
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    submitFamilyForm() {
        const form = document.querySelector('form');
        const formData = new FormData(form);
        const formDataObj = Object.fromEntries(formData.entries());
        formDataObj.family_size = this.state.familyMemberList.length
        formDataObj.googleRecaptchaResponse = this.state.reCaptchaToken
        PostData("front/family_registration", formDataObj)
            .then(result => {
                if (result.status === false) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: Object.values(result.message)[0]
                        },
                    })
                } else {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: result.response
                        }
                    })
                    this.handleClose()
                }
            })
        window.scrollTo(0, 0)
    }

    handleClose() {
        this.setState({
            addFamilyMemberModal: false,
            familyMemberList: [],
            first_name: "",
            last_name: "",
            father_name: "",
            grandfather_name: "",
            phone: "",
            email: "",
            vas_name: "",
            full_address: "",
            marital_status: "",
            gender: "",
            age:""
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    changeValueSelect = (selection, event) => {
        this.setState({
            [event.name]: selection
        })
    }

    addSuffix(number) {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return `${number}th`;
        } else if (lastDigit === 1) {
            return `${number}st`;
        } else if (lastDigit === 2) {
            return `${number}nd`;
        } else if (lastDigit === 3) {
            return `${number}rd`;
        } else {
            return `${number}th`;
        }
    }

    addFamilyMemberRow() {
        let currentIndex = this.state.familyMemberList.length;
        this.state.familyMemberList.push(<div key={currentIndex} className="mb-3">
                <div className="d-flex flex-wrap ">
                    <p>Add {this.addSuffix(currentIndex + 1)} Family Member
                        Details</p>
                    <Row as={"ul"}>
                        <Col as={"li"} sm={4}>
                            <Form.Control
                                type="text"
                                name={"first_name_" + currentIndex}
                                onChange={this.changeValue}
                                placeholder="FIRST NAME"/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <Form.Control
                                type="text"
                                name={"last_name_" + currentIndex}
                                onChange={this.changeValue}
                                placeholder="LAST NAME"/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <Form.Control
                                type="text"
                                name={"father_name_" + currentIndex}
                                onChange={this.changeValue}
                                placeholder="FATHER NAME"/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <Select
                                name={"gender_" + currentIndex}
                                placeholder="Select Gender"
                                onChange={this.changeValueSelect}
                                options={this.state.gender_options}/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <Select
                                style={{maxHeight: '48px'}}
                                name={"marital_status_" + currentIndex}
                                placeholder="Marital Status"
                                onChange={this.changeValueSelect}
                                options={this.state.married_options}/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <Select
                                style={{maxHeight: '48px'}}
                                name={"relationship_" + currentIndex}
                                placeholder="Select Relationship"
                                onChange={this.changeValueSelect}
                                options={this.state.relationship_options}/>
                        </Col>
                        <Col as={"li"} sm={4}>
                            <button className="btn mb-2 mx-1 mt-0" type="button" onClick={() => {
                                this.setState({
                                    familyMemberList: this.state.familyMemberList.splice(currentIndex, 1),
                                })
                            }
                            }>
                                <em className="fa fa-trash"/>
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
        this.setState(
            {
                familyMemberList: this.state.familyMemberList,
            }
        )
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us" style={{ paddingBottom : 250}}>
                    <div className="container">
                        <div className="contact-form">
                            <Form id="contact_form" className="contact-form" method="post">
                                <Row as={"ul"}>
                                    <Col sm={12}>
                                        <Alert show={this.state.commonAlert.show}
                                               variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="first_name" id="name"
                                                          value={this.state.first_name} onChange={this.changeValue}
                                                          placeholder="FIRST NAME"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="last_name" id="last_name"
                                                          value={this.state.last_name} onChange={this.changeValue}
                                                          placeholder="LAST NAME"/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="father_name" id="father_name"
                                                          placeholder="FATHER NAME" value={this.state.father_name}
                                                          onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="grandfather_name"
                                                          id="grand_father_name"
                                                          placeholder="GRAND FATHER NAME"
                                                          value={this.state.grandfather_name}
                                                          onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="number" name="phone" id="phone" placeholder="PHONE"
                                                          value={this.state.phone} onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="email" id="email" placeholder="EMAIL"
                                                          value={this.state.email} onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                </Row>
                                <Row as={"ul"}>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="text" name="vas_name" id="vas_name"
                                                          placeholder="VAS NAME" value={this.state.vas_name}
                                                          onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <Select
                                            name={"gender"}
                                            value={this.state.gender}
                                            placeholder="Select Gender"
                                            onChange={this.changeValueSelect}
                                            options={this.state.gender_options}/>
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <Select
                                            name="marital_status"
                                            placeholder="Select Marital Status"
                                            value={this.state.marital_status}
                                            onChange={this.changeValueSelect}
                                            options={this.state.married_options}
                                        />
                                    </Col>
                                    <Col as={"li"} sm={4}>
                                        <label>
                                            <Form.Control type="number" name="age" id="age" placeholder="AGE"
                                                          value={this.state.age} onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={8}>
                                        <label>
                                            <Form.Control as={"textarea"} name="full_address" id="full_address"
                                                          rows={3}
                                                          placeholder="FULL ADDRESS" value={this.state.full_address}
                                                          onChange={this.changeValue}/>
                                        </label>
                                    </Col>
                                </Row>

                                <div id="family_member">
                                    {this.state.familyMemberList.map((familyMemberData) => (familyMemberData))}
                                    <Button variant={"primary"} className="mb-3" onClick={() => {
                                        this.addFamilyMemberRow()
                                    }}>Add Family Member</Button>
                                </div>

                                <div className="text-center">
                                    <Button variant={"primary"} onClick={() => {
                                        this.submitFamilyForm()
                                    }}>Submit</Button>
                                </div>
                                <GoogleReCaptchaProvider
                                    reCaptchaKey="6Lcni2wkAAAAAKykRhLubTzdf1b_ew-uKwQO3Wnc"
                                    scriptProps={{
                                        async: false, // optional, default to false,
                                        defer: false, // optional, default to false
                                        appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                        nonce: undefined // optional, default undefined
                                    }}
                                >
                                    <GoogleReCaptcha onVerify={this.setTokenValue}/>
                                </GoogleReCaptchaProvider>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>

            <Footer/>
        </>)
    }
}

export default RegisterYourFamily;