import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import {Alert, Button, Col, Form, Modal} from "react-bootstrap";
import {PostData, uploadFiles} from "../api_key/PostData";
import {GetData} from "../api_key/GetData";

class AdminEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUsername: "",
            loginUserUUID: "",
            loginUserToken: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            addEditEventModal: false,
            eventImage: "",
            eventImageErr: "",
            eventTitle: "",
            eventTitleErr: "",
            eventAddress: "",
            eventStartTimeDateErr: "",
            eventStartTimeDate: "",
            eventEndTimeDateErr: "",
            eventEndTimeDate: "",
            eventAddressErr: "",
            eventDescription: "",
            eventDescriptionErr: "",
            editEvent: false,
            deleteEventModal: false,
        }
        document.title = this.props.pageTitle
        this.insertNewEvent = this.insertNewEvent.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.changeFileValue = this.changeFileValue.bind(this)
        this.loadAllEvents = this.loadAllEvents.bind(this)
    }

    componentDidMount() {
        const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
        this.setState({
            loginUsername: sessionData[0].full_name,
            loginUserUUID: sessionData[0].uuid,
            loginUserToken: sessionData[0].token
        });

        this.loadAllEvents()
    }

    insertNewEvent() {
        if(!this.state.editEvent && this.state.eventImage === "") {
            this.setState({eventImageErr: "Choose Image"})
            return;
        } else {
            this.setState({eventImageErr: ""})
        }
        if(this.state.eventTitle === "") {
            this.setState({eventTitleErr: "Enter Event Title"})
            return;
        } else {
            this.setState({eventTitleErr: ""})
        }
        if(this.state.eventStartTimeDate === "") {
            this.setState({eventStartTimeDateErr: "Enter Event Start Time and Date"})
            return;
        } else {
            this.setState({eventStartTimeDateErr: ""})
        }
        if(this.state.eventEndTimeDate === "") {
            this.setState({eventEndTimeDateErr: "Enter Event End Time and Date"})
            return;
        } else {
            this.setState({eventEndTimeDateErr: ""})
        }
        if(this.state.eventAddress === "") {
            this.setState({eventAddressErr: "Enter Event Address"})
            return;
        } else {
            this.setState({eventAddressErr: ""})
        }
        if(this.state.eventDescription === "") {
            this.setState({eventDescriptionErr: "Enter Event Description"})
            return;
        } else {
            this.setState({eventDescriptionErr: ""})
        }
        const formData = new FormData();
        if(!this.state.editEvent || this.state.eventImage !== "") {
            formData.append('banner_image_file', this.state.eventImage, this.state.eventImage.name);
        }
        formData.append('title', this.state.eventTitle);
        formData.append('location', this.state.eventAddress);
        formData.append('start_date_time', this.state.eventStartTimeDate);
        formData.append('end_date_time', this.state.eventEndTimeDate);
        formData.append('description', this.state.eventDescription);
        this.state.editEvent && formData.append('uuid', this.state.editUuid);
        const eventApi = this.state.editEvent ? "/events/edit_event" : "/events/add_event";
        uploadFiles(eventApi, formData)
            .then(result => {
                if (result.status === true) {
                    this.loadAllEvents();
                    this.setState({
                        commonAlert: {show: true, variant: "success", message: result.response},
                        addEditEventModal: false
                    })
                    setTimeout(() => this.handleClose(), 3000)
                } else if (result.status === 401) {
                    this.setState({
                        commonAlert: {show: true, variant: "danger", message: result.messages.message}
                    })
                } else if (result.status === false) {
                    this.setState({
                        commonAlert: {show: true, variant: "danger", message: result.message}
                    })
                }
            })
    }

    loadAllEvents() {
        GetData("/events/get_all")
            .then(result => {
                this.setState({eventsData: result.response});
                const listItems = result.response.map((data, index) =>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={index}>
                        <div className="shadow rounded p-4">
                            <h4 className={"text-dark"}>{data.title}</h4>
                            <div className="border-primary border-bottom border-top py-2 mb-2">
                                <h6 className="mx-2 my-1 text-dark fw-normal event-address">
                                    <em className="fa fa-map-marker"></em> {data.location}
                                </h6>
                                <h6 className="mx-2 my-1 text-dark fw-normal event-time">
                                    <em className="fa fa-calendar"></em> {data.start_date_time}
                                </h6>
                                <h6 className="mx-2 my-1 text-dark fw-normal event-time">
                                    <em className="fa fa-clock-o"></em> {data.end_date_time}
                                </h6>
                            </div>
                            <p className={"event-description-pre"}>{data.description}</p>

                            <img className="img-responsive w-100 border border-light rounded p-1" src={data.banner_image_file} alt=""
                                 style={{height: 160, objectFit: "cover"}}/>
                            <div className="d-flex justify-content-between flex-wrap mt-3">
                                <Button className="btn btn-info text-white" onClick={() => {
                                    this.setState({
                                        editUuid: data.uuid,
                                        eventTitle: data.title,
                                        eventAddress: data.location,
                                        eventDescription: data.description,
                                        eventStartTimeDate: data.start_date_time,
                                        eventEndTimeDate: data.end_date_time,
                                        addEditEventModal: true,
                                        editEvent: true
                                    })
                                }}>
                                    <em className="fa fa-edit"></em> Edit
                                </Button>
                                <Button className="btn btn-danger" onClick={() => {
                                    this.setState({deleteEventModal: true, deleteUuid: data.uuid,})
                                }}>
                                    <em className="fa fa-trash"></em> Delete
                                </Button>
                            </div>
                        </div>
                    </Col>
                );
                this.setState({eventsDataItems: listItems, dataLoader: false});
            })
    }

    handleClose() {
        this.setState({
            addEditEventModal: false,
            eventImage: "",
            eventTitle: "",
            eventAddress: "",
            eventStartTimeDate: "",
            eventEndTimeDate: "",
            eventDescription: "",
            eventImageErr: "",
            eventTitleErr: "",
            eventStartTimeDateErr: "",
            eventEndTimeDateErr: "",
            eventAddressErr: "",
            eventDescriptionErr: "",
            editEvent: false,
            deleteEventModal: false,
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    changeFileValue = (event) => {
        this.setState({
            eventImage: event.target.files[0]
        })
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.setState({
                                    addEditEventModal: true,
                                })
                            }}>Add New Event</Button>
                        </div>
                        <Modal show={this.state.addEditEventModal} onHide={this.handleClose} backdrop={"static"}
                               centered keyboard={false}>
                            <Modal.Header closeButton className={"border-light"}>
                                <Modal.Title className={"text-dark"}>Add New Event</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                    </Alert>
                                    <Form.Group className="mb-3" controlId="eventImage">
                                        <Form.Label>Event Image</Form.Label>
                                        <Form.Control type="file"
                                                      placeholder="Event Image"
                                                      className={"bg-white border-light"}
                                                      name={"eventImage"}
                                                      onChange={this.changeFileValue}/>
                                        {this.state.eventImageErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventImageErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventTitle">
                                        <Form.Label>Event Title</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder="Event Title"
                                                      className={"bg-white border-light"}
                                                      name={"eventTitle"}
                                                      value={this.state.eventTitle}
                                                      onChange={this.changeValue}/>
                                        {this.state.eventTitleErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventTitleErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventStartTimeDate">
                                        <Form.Label>Event Start Date & Time</Form.Label>
                                        <Form.Control type="datetime-local"
                                                      placeholder="Event Date"
                                                      className={"bg-white border-light"}
                                                      name={"eventStartTimeDate"}
                                                      value={this.state.eventStartTimeDate}
                                                      onChange={this.changeValue}/>
                                        {this.state.eventStartTimeDateErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventStartTimeDateErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventEndTimeDate">
                                        <Form.Label>Event End Date & Time</Form.Label>
                                        <Form.Control type="datetime-local"
                                                      placeholder="Event Time"
                                                      className={"bg-white border-light"}
                                                      name={"eventEndTimeDate"}
                                                      value={this.state.eventEndTimeDate}
                                                      onChange={this.changeValue}/>
                                        {this.state.eventEndTimeDateErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventEndTimeDateErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventAddress">
                                        <Form.Label>Event Address</Form.Label>
                                        <Form.Control as="textarea"
                                                      placeholder="Event Address"
                                                      rows={3}
                                                      className={"bg-white border-light"}
                                                      name={"eventAddress"}
                                                      value={this.state.eventAddress}
                                                      onChange={this.changeValue}/>
                                        {this.state.eventAddressErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventAddressErr}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="eventDescription">
                                        <Form.Label>Event Description</Form.Label>
                                        <Form.Control as="textarea"
                                                      rows={3}
                                                      placeholder="Event Description"
                                                      className={"bg-white border-light"}
                                                      name={"eventDescription"}
                                                      value={this.state.eventDescription}
                                                      onChange={this.changeValue}/>
                                        {this.state.eventDescriptionErr && <Form.Label
                                            className={"text-danger"}>{this.state.eventDescriptionErr}</Form.Label>}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className={"border-light"}>
                                <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Close</Button>
                                <Button variant={"success"} onClick={this.insertNewEvent} className={"ml-2"}>Save Changes</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={this.state.deleteEventModal} onHide={this.handleClose} centered backdrop="static"
                               keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>Are you sure want to delete this Event?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={(event) => {
                                    PostData("/events/delete_event", {uuid: this.state.deleteUuid})
                                        .then(result => {
                                            this.setState({
                                                commonAlert: {show: true, variant: "success", message: result.response},
                                                deleteEventModal: false
                                            })
                                            setTimeout(() => {
                                                this.setState({commonAlert: {show: false, variant: "success"}})
                                            }, 3000);
                                            this.loadAllEvents()
                                        })
                                }}>Yes</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>No</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="row g-4">
                            <div className="col-12">
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                </Alert>
                            </div>
                            {this.state.eventsDataItems}
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminEvents;