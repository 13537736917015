import React from "react";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Link} from "react-router-dom";
import {GetData} from "../api_key/GetData";
import moment from "moment";

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {eventsDataItems: []};
        document.title = this.props.pageTitle
        this.loadAllEvents = this.loadAllEvents.bind(this)
    }

    componentDidMount() {
        this.loadAllEvents()
        window.scrollTo(0, 0);
    }
    loadAllEvents() {
        GetData("/front/get_events")
            .then(result => {
                this.setState({eventsData: result.response});
                const now = new Date();
                const pastEvents = result.response
                    .filter(event => new Date(event.start_date_time) < now)
                    .sort((a, b) => new Date(b.start_date_time) - new Date(a.start_date_time)); // Descending order
                const upcomingEvents = result.response
                    .filter(event => new Date(event.start_date_time) >= now)
                    .sort((a, b) => new Date(a.start_date_time) - new Date(b.start_date_time)); // Ascending order
                let upcomingEventsList = []
                if(upcomingEvents.length > 0) {
                    upcomingEventsList = upcomingEvents.map((data, index) =>
                        <Row key={index}>
                            <Col md={12}>
                                <ListGroup as={"ul"}>
                                    <ListGroup.Item as={"li"} className={"col-sm-5 no-padding eve-img bg-transparent border-0"}>
                                        <img className="img-responsive" src={data.banner_image_file} alt=""/>
                                        <div className="date">{moment(data.start_date_time).date()} <p>{moment(data.start_date_time).format('MMMM, ')}</p></div>
                                    </ListGroup.Item>
                                    <ListGroup.Item as={"li"} className={"col-sm-7 no-padding bg-transparent border-0"}>
                                        <div className="event-detail">
                                            <h4>{data.title}</h4>
                                            <span><em className="ion-ios-location-outline"></em>{data.location}</span>
                                            <span><em className="ion-ios-clock-outline"></em> {moment(data.start_date_time).format('MMMM Do')} To {moment(data.end_date_time).format('MMMM Do')} </span>
                                            <p className={"event-description-pre"}>{data.description}</p>
                                            <Link to={"/register_your_interest?uuid=" + data.uuid} className={"btn btn-primary d-inline-block w-100 w-md-50 mr-2"}>Register Your Interest</Link>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    );
                } else {
                    upcomingEventsList = <h5 className="text-center mb-4">No Upcoming Event Found</h5>
                }
                let pastEventsList = []
                if(pastEvents.length > 0) {
                    pastEventsList = pastEvents.map((data, index) =>
                        <Row key={index}>
                            <Col md={12}>
                                <ListGroup as={"ul"}>
                                    <ListGroup.Item as={"li"}
                                                    className={"col-sm-5 no-padding eve-img bg-transparent border-0"}>
                                        <img className="img-responsive" src={data.banner_image_file} alt=""/>
                                        <div className="date">{moment(data.start_date_time).date()}
                                            <p>{moment(data.start_date_time).format('MMMM, ')}</p></div>
                                    </ListGroup.Item>
                                    <ListGroup.Item as={"li"} className={"col-sm-7 no-padding bg-transparent border-0"}>
                                        <div className="event-detail">
                                            <h4>{data.title}</h4>
                                            <span><em className="ion-ios-location-outline"></em>{data.location}</span>
                                            <span><em className="ion-ios-clock-outline"></em> {moment(data.start_date_time).format('MMMM Do')} To {moment(data.end_date_time).format('MMMM Do')} </span>
                                            <p className={"event-description-pre"}>{data.description}</p>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    );
                } else {
                    pastEventsList = <h5 className="text-center mb-4">No Past Event Found</h5>
                }
                this.setState({eventsDataItems: {
                        upcomingEvents: upcomingEventsList, pastEvents: pastEventsList
                    }, dataLoader: false});
            })
    }
    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <div className="container">
                        <section className="sec-100px event event-page">
                            <h2 className="page-title">Let's Celebrate Upcoming Events</h2>
                            {this.state.eventsDataItems.upcomingEvents}

                            <h2 className="page-title text-success">Our Memorable Events</h2>
                            {this.state.eventsDataItems.pastEvents}

                            <h2 className="page-title text-primary">Near by Hotel Lists</h2>
                            <Row className={"d-flex align-self-center flex-wrap m-0"}>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>Embassy Suites by Hilton Hampton Hotel & Convention Center.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.hilton.com/en/attend-my-event/mokhasanfamilyreunion/"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>Courtyard Marriott – Hampton.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.marriott.com/event-reservations/reservation-link.mi?id=1729799527160&key=GRP&guestreslink2=true"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>Hilton Garden Inn – Hampton Coliseum.</span><br/>
                                            <span style={{fontWeight: "bold"}}>Mokhasan Family Reunion reservations at the Hilton Garden Inn Hampton Coliseum Central.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://hilton.com"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>Holiday Inn Express – Hampton.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.hiexpress.com/redirect?path=rates&brandCode=EX&localeCode=en&regionCode=1&hotelCode=PHFHV&checkInDate=24&checkInMonthYear=062025&checkOutDate=27&checkOutMonthYear=062025&_PMID=99801505&GPC=MFR&cn=no&viewfullsite=true"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>Hyatt Place Hampton Roads Convention Center.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.hyatt.com/shop/rooms/orfzh?checkinDate=2025-07-24&checkoutDate=2025-07-27&rooms=1&adults=1&kids=0&corp_id=G-M25P&brands[]=place&location=Hyatt%20Place%20Hampton%20Convention%20Center"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>SpringHill Suites – Hampton.</span><br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.marriott.com/event-reservations/reservation-link.mi?id=1732379467764&key=GRP&guestreslink2=true&app=resvlink"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>

                <Footer/>
            </>
        )
    }
}

export default Events;