import React from "react";
import {Link, Navigate} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import $ from "jquery";

class AdminHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUsername: "",
            loginUserUUID: "",
            loginRedirect: "",
        }
        this.userLogout = this.userLogout.bind(this)
    }

    userLogout() {
        document.cookie = "mokhasanLogin=;max-age=0;";
        this.setState({loginRedirect: true})
    }

    componentDidMount() {
        if (document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))) {
            const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
            let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
            this.setState({
                loginUsername: sessionData[0].full_name,
                loginUserUUID: sessionData[0].uuid,
                loginUserToken: sessionData[0].token
            });
        } else {
            this.setState({
                loginRedirect: true,
            })
        }
    }

    render() {
        return (<>
                {(this.state.loginRedirect) && <Navigate to="/adminPages" replace={true}/>}
                <nav className="navbar navbar-expand navbar-dark sticky-top px-4 py-0 bg-white">
                    <Link to={""} className="navbar-brand d-flex d-lg-none me-4">
                        <h2 className="text-primary mb-0"><em className="fa fa-user-edit"></em></h2>
                    </Link>
                    <Link to={""} className="sidebar-toggler border border-primary flex-shrink-0 bg-white" onClick={() => {
                        $('.sidebar, .content').toggleClass("open");
                    }}>
                        <em className="fa fa-bars"></em>
                    </Link>
                    <form className="d-none d-md-flex ms-4">
                        <input className="form-control bg-white" type="search" placeholder="Search"/>
                    </form>
                    <div className="navbar-nav align-items-center ms-auto">
                        <Dropdown className="nav-item">
                            <Dropdown.Toggle as={"a"} className={"nav-link"}>
                                <img className="rounded-circle me-lg-2 border-light border" src="/assets/images/profile-picture.png" alt=""
                                     style={{width: "40px", height: "40px"}}/>
                                <span className="d-none d-lg-inline-flex">{this.state.loginUsername}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={this.userLogout} className="dropdown-item"><em className="fa fa-sign-out"></em> Log Out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </nav>
            </>
        );
    }
}

export default AdminHeader;